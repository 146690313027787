<template>
  <div>
    <CSBreadcrumb/>
    <div class="filter-panel">
      <CSSelect>
        <el-date-picker
            v-model="queryParams.startDate"
            type="datetime"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            placeholder="请选择开始时间"
        />
      </CSSelect>
      <span>-</span>
      <CSSelect>
        <el-date-picker
            v-model="queryParams.endDate"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            type="datetime"
            placeholder="请选择结束时间"
        />
      </CSSelect>
      <CSSelect>
        <select v-model="queryParams.isFace">
          <option value="">不限</option>
          <option v-for="(name, val) in faceStatus" :value="val" :key="name">
            {{ name }}
          </option>
        </select>
      </CSSelect>
      <CSSelect>
        <select style="width: 160px" v-model="queryParams.model">
          <option value="">识别类型不限</option>
          <option value="1">虹软</option>
          <option value="4">飞浆</option>
        </select>
      </CSSelect>
      <button class="btn btn-primary" @click="queryCaptureLog()">查询</button>
    </div>
    <div class="result-panel">
      <CSTable :thead-top="60">
        <template v-slot:thead>
          <tr>
            <th>抓图时间</th>
            <th>抓取图片</th>
            <!--                        <th>是否比对出人脸</th>-->
            <th>对比图片</th>
            <th>相似度</th>
            <th>识别类型</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tr v-for="log in logs" :key="log.id">
            <td class="full-date-td">{{ log.createTime }}</td>
            <td>
              <div v-if="log.photo || log.cutPhoto">
                <span v-if="log.cutPhoto"
                      class="allow-click"
                      @click="lookPhoto(log.cutPhoto, '人脸图片')"
                >人脸</span>
                <template v-else>-</template>
                &nbsp;
                <span
                    class="allow-click"
                    @click="lookPhoto(log.photo, '全景图片')"
                >全景图</span>
                <!--                <img style="height: 80px;" :src="log.cutPhoto" v-if="log.cutPhoto"-->
                <!--                     @click="lookPhoto(log.cutPhoto, '人脸图片')">-->
                <!--                <img style="height: 80px; margin-left: 10px;" :src="log.photo" v-if="log.photo"-->
                <!--                     @click="lookPhoto(log.photo, '全景图片')">-->
              </div>
              <template v-else>-</template>
            </td>
            <!--                        <td>{{faceStatus[log.isFace] || '-'}}</td>-->
            <td>
              <span
                  v-if="log.contrastPhoto"
                  class="allow-click"
                  @click="lookPhoto(log.contrastPhoto, '对比图片')"
              >查看</span>
              <!--              <img style="height: 80px;" :src="log.contrastPhoto" v-if="log.contrastPhoto"
                                 @click="lookPhoto(log.contrastPhoto, '对比图片')">-->
              <template v-else>-</template>

            </td>
            <td>{{ log.similarity || "-" }}</td>
            <td>{{ log.model || "-" }}</td>
          </tr>
        </template>
      </CSTable>
      <Pagination name="paginations" componentName="Paginations"/>
      <ViewImageModal/>
    </div>
  </div>
</template>

<script>
import CSSelect from "@/components/common/CSSelect";
import {queryFaceDiffLogUrl} from "@/requestUrl";
import Pagination from "@/components/Pagination";
import dayjs from "dayjs";
import ViewImageModal from "@/components/ViewImageModal";
import CSBreadcrumb from "@/components/common/CSBreadcrumb";
import CSTable from "@/components/common/CSTable";

export default {
  name: "CaptureFaceDiffLog",
  components: {CSTable, CSBreadcrumb, ViewImageModal, Pagination, CSSelect},
  props: {
    id: Number,
  },
  data() {
    return {
      queryParams: {
        startDate: dayjs().format("YYYY-MM-DD 00:00"),//必填
        endDate: dayjs().format("YYYY-MM-DD 23:59"),//必填
        isFace: '',//1.对比出人脸 0.相反
        model: ''
      },
      logs: [],
      faceStatus: {
        1: '已识别到',
        0: '没识别到'
      }
    }
  },
  mounted() {
    this.$vc.on(this.$route.path, "pagination_page", "event", this.queryCaptureLog);
    this.queryCaptureLog();
  },
  methods: {
    /**
     * 查看图片
     * @param {String} src 图片地址
     * @param {String} title 弹窗标题
     * */
    lookPhoto(src, title) {
      this.$vc.emit(this.$route.path, "viewImageModal", "view", {
        photos: [src],
        title
      });
    },
    /**
     * 查询抓拍记录
     * @param {Number} pageNo 页码
     * @param {Number} pageSize 数量
     * */
    queryCaptureLog(pageNo = 1, pageSize = 10) {
      this.$fly.post(queryFaceDiffLogUrl, {
        ...this.queryParams,
        cameraId: this.id,
        pageNo,
        pageSize
      })
          .then(res => {
            if (pageNo === 1) {
              console.log(this.$route);
              this.$vc.emit(`/flow/securityCamera/face/diff/${this.$route.params.id}`, "pagination", "init", {
                total: res.data.total,
                pageSize,
                currentPage: pageNo,
              });
            }
            this.logs = res.data.datas || [];
          })
    },
  }
}
</script>

<style scoped>

</style>
